<template>
  <div class="home_warp">
    <!-- 顶部导航 -->
    <titleNav title="灵活就业缴费标准" :left-arrow="true" left-text="" />
    <!-- 内容 -->
    <div class="imagne">
        <img src="@/assets/images/组 1@2x (14)(1).png" alt="">
    </div>
  </div>
</template>

<script>
import titleNav from "@/components/conetnts/titleNav.vue";
// import floatBtn from "@/components/floatBtn.vue";
import { Button, Dialog, Icon, Toast, Field, Checkbox, CheckboxGroup } from "vant";
import { banner, price, indexs } from "@/network/index.js";
export default {
  components: {
    titleNav,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
        checked: true,
        tel:'',
        sms:'',
    };
  },
  created() {
    window.scrollTo(0,0);
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.home_warp {
//   height: 100%;
  background: #ffffff;
  background-color: #ffffff;
  position: relative;
  padding: 10px;
  .imagne{
      width: 100%;
      img{
          width: 100%;
      }
  }
}
</style>
